<script>
    import {createEventDispatcher} from 'svelte/internal';

    export let label = '';
    export let value = 0;
    export let minValue = 0;
    export let maxValue = 0;

    const dispatch = createEventDispatcher();

    const decValue = () => {
        if (value > minValue) {
            value--;
            dispatch("change", value);
        }
    }
    const incValue = () => {
        if (value < maxValue) {
            value++;
            dispatch("change", value);
        }
    }
</script>

{#if label}
    <label>{ label }</label>
{/if}
<div class="tickets-input">
    <div class="minus-plus" on:click="{ decValue }">-</div>
    <input type="text" bind:value={ value } readonly>
    <div class="minus-plus" on:click="{ incValue }">+</div>
</div>

<style>
    label {
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .tickets-input {
        display: flex;
        border-radius: 4px;
        -webkit-box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.59);
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.59);
    }


    .tickets-input .minus-plus {
        flex: 0 0 auto;
        width: 30px;
        font-size: 21px;
        font-weight: 500;
        text-align: center;
        align-items: center;
        cursor: pointer;
        padding-top: 7px;
    }

    .tickets-input input {
        flex: 0 0 auto;
        width: calc(100% - 60px);
        font-size: 18px;
        text-align: center;
        background-color: transparent;
        border: none;
        padding: 10px 0;
    }

    @media (min-width: 576px) {
        label {
            font-size: 14px;
        }
    }
</style>
