<script>
    export let right = 10;
    export let loading = false;
</script>

{#if loading}
    <div class="loading-icon" style="right: { right }px;">
        <div class="spinner">
            <img src="https://icongr.am/entypo/ccw.svg?size=20&color=707070"/>
        </div>
    </div>
{/if}

<style>
    .loading-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .spinner {
        /*margin: 20px;*/
        width: 20px;
        height: 20px;
        -webkit-animation-name: spin;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spin;
        -moz-animation-duration: 1000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spin;
        -ms-animation-duration: 1000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;

        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @-ms-keyframes spin {
        from {
            -ms-transform: rotate(360deg);
        }
        to {
            -ms-transform: rotate(0deg);
        }
    }

    @-moz-keyframes spin {
        from {
            -moz-transform: rotate(360deg);
        }
        to {
            -moz-transform: rotate(0deg);
        }
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(360deg);
        }
        to {
            -webkit-transform: rotate(0deg);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
</style>