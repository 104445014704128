<script>
    import app from "../stores/app";
    import {countries} from "../lib/countries";
    import {
        TICKET_CODES,
        TRANSFER_TYPE,
        PAYMENT_GATEWAYS,
        CARDLINK_ROUTE_URL,
        PAYPAL_ROUTE_URL,
    } from "../lib/constants";
    import Button from "../controls/Button.svelte";
    import HotelAutoComplete from "../components/HotelAutoComplete.svelte";
    import Loader from "../components/Loader.svelte";
    import {isValidEmail, formatDate, setTransferStartDate, setGtagEvent} from '../lib/lib';
    import R from "../lib/requests";

    let transaction = null;
    let emailConfirm = '';
    let termsChecked = false;
    let marketingPermissionsChecked = false;
    let showWhatToExpect = false;
    let routeFormAction = '';
    let referenceId = '';
    let loading = false;

    const getFormError = () => {

        if (!$app.full_name) {
            return 'no_full_name';
        }

        if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
            if (!$app.transfer.hotel && !$app.hotel_name) {
                return 'no_hotel';
            }
        }

        if ($app.transfer_type_id == TRANSFER_TYPE.PRIVATE_TRANSFER) {
            if (!$app.hotel_name) {
                return 'no_hotel';
            }
        }

        if ($app.transfer_type_id != TRANSFER_TYPE.NO_TRANSFER) {
            if (!$app.phone) {
                return 'no_phone';
            }
        }

        if (!$app.email || !isValidEmail($app.email)) {
            return 'invalid_email';
        }

        if (emailConfirm !== $app.email) {
            return 'confirm_email';
        }

        let error = null;
        if ($app.payment_gateway_id == PAYMENT_GATEWAYS.CARDLINK) {
            Object.keys($app.bill_info_json).map(Key => {
                if (!$app.bill_info_json[Key]) {
                    error = 'no_bill_info';
                }
            });
            if (error) return error;
        }

        if (!termsChecked) {
            return 'read_terms';
        }

        return null;
    };

    const submitTransaction = () => {

        let error = getFormError();
        if (error) {
            alert($app.lang.alerts[error]);
            return false;
        }

        if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
            // Check if transfer date is correct
            let startDate = setTransferStartDate($app.settings.dates);
            if ($app.transfer.date_at < startDate) {
                alert(`You cannot book a transfer to Watercity by bus before ${formatDate(startDate, 'd-m-y')}.`);
                return;
            }
        }

        // set the tickets
        let tickets = [];
        let totalTickets = 0;
        let totalTicketsPrice = 0;
        let totalTransferPrice = 0;

        // add tickets in transaction
        $app.products.map(product => {
            if (product.persons > 0) {
                if (product.code === TICKET_CODES.FAMILY) {

                    // for Family class add 2 adults and 2 children in products
                    let GROUPS = $app.GROUPS;
                    tickets.push({
                        ticket_id: GROUPS.FAMILY.tickets.ADULT.id,
                        quantity: 2,
                        price: `${GROUPS.FAMILY.tickets.ADULT.price * 2}`,
                    });
                    tickets.push({
                        ticket_id: GROUPS.FAMILY.tickets.CHILD.id,
                        quantity: 2,
                        price: `${GROUPS.FAMILY.tickets.CHILD.price * 2}`,
                    });

                    totalTickets += 4;
                    totalTicketsPrice = 40.00;

                } else {

                    tickets.push({
                        ticket_id: product.id,
                        quantity: product.persons,
                        price: `${product.ticketsPrice}`,
                    });
                    totalTickets += product.persons;
                    totalTicketsPrice += product.ticketsPrice;

                }

                totalTransferPrice += product.transferPrice;
            }
        });

        // set the transfer
        let transferData = {};
        if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
            transferData = {
                person_count: totalTickets,
                price: totalTransferPrice,
                pickup_point_id: $app.transfer.pickup_point.id,
                pickup_point_name: $app.transfer.pickup_point.name,
                date_at: $app.transfer.date_at,
                pickup_time: $app.transfer.pickup_time,
                zone_id: $app.transfer.zone_id,
                metadata: {},
            }
        }

        // set the hotel
        let hotel = {
            id: 0,
            name: '',
            room: $app.hotel_room,
            phone: $app.phone,
        };
        if ($app.transfer && $app.transfer.hotel) {
            hotel.id = $app.transfer.hotel.id;
            hotel.name = $app.transfer.hotel.name;

            // keep map locations for later reference on email
            if ($app.transfer.hotel.lat && $app.transfer.hotel.lng) {
                transferData.metadata.map_route = [`${$app.transfer.hotel.lat},${$app.transfer.hotel.lng}`, `${$app.transfer.pickup_point.lat},${$app.transfer.pickup_point.lng}`];
            }
        } else {
            hotel.name = $app.hotel_name ? $app.hotel_name : '';
        }

        totalTicketsPrice = totalTicketsPrice.toFixed(2);
        totalTransferPrice = totalTransferPrice > 0 ? totalTransferPrice.toFixed(2) : 0;
        let totalPrice = $app.totalPrice.toFixed(2);

        transaction = {
            email: $app.email,
            marketing_permission: marketingPermissionsChecked ? 1 : 0,
            data: {
                language: $app.language,
                // partner: $app.partner || null,  ADD WHEN PARTNER IS READY
                full_name: $app.full_name,
                phone: $app.phone,
                email: $app.email,
                transfer_type_id: $app.transfer_type_id,
                tickets: {
                    ticket_discount_id: $app.ticket_discount_id,
                    tickets: tickets,
                },
                transfer: transferData,
                hotel: hotel,
                total_tickets: totalTickets,
                total_tickets_price: totalTicketsPrice,
                total_transfer_price: totalTransferPrice,
                total_price: totalPrice,
                payment_gateway_id: $app.payment_gateway_id,
                payment_id: '',
                bill_info_json: $app.bill_info_json,
            }
        }

        // console.log("transaction", transaction);
        // return;

        const onSuccess = result => {
            loading = false;
            referenceId = result;

            if ($app.payment_gateway_id == PAYMENT_GATEWAYS.CARDLINK) {
                routeFormAction = CARDLINK_ROUTE_URL;
            } else {
                routeFormAction = PAYPAL_ROUTE_URL;
            }

            setTimeout(() => {
                let frm = document.getElementById('route');
                frm.submit();
            }, 300);
        };

        const onFailure = err => {
            loading = false;
        };


        loading = true;
        R.handle(R.tickets.save, transaction, onSuccess, onFailure);
    };

    const setPaymentMethod = (method_id) => {
        $app.payment_gateway_id = method_id;
    };

    setGtagEvent("begin_checkout", '', $app.totalPrice, '', $app.payment_gateway_id, $app.gTagItems);

    if (!$app.bill_info_json.country) {
        $app.bill_info_json.country = $app.language === 'el' ? 'GR' : 'GB';
    }
</script>

<div class="container mt-lg">
    <form onsubmit="return false;">
        <div class="row form-group align-items-center">
            <div class="column column-left">
                <label>{ $app.lang.full_name }</label>
            </div>
            <div class="column column-right">
                <input type="text" bind:value="{ $app.full_name }" required>
            </div>
        </div>
        {#if $app.transfer_type_id != TRANSFER_TYPE.NO_TRANSFER}
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.phone }</label>
                </div>
                <div class="column column-right">
                    <input type="tel" bind:value="{ $app.phone }" required>
                </div>
            </div>
        {/if}
        <div class="row form-group align-items-center">
            <div class="column column-left">
                <label>{ $app.lang.email }</label>
            </div>
            <div class="column column-right">
                <input type="email" bind:value="{ $app.email }" required>
            </div>
        </div>
        <div class="row form-group align-items-center">
            <div class="column column-left">
                <label>{ $app.lang.confirm_email }</label>
            </div>
            <div class="column column-right">
                <input type="email" bind:value="{ emailConfirm }" required>
            </div>
        </div>
        {#if $app.language === 'en'}
            {#if $app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER}
                <div class="row form-group align-items-center">
                    <div class="column column-left">
                        <label>Pickup from</label>
                    </div>
                    <div class="column column-right">
                        <input type="text" value="{ $app.transfer.pickup_point.name }" disabled>
                    </div>
                </div>
                <div class="row form-group align-items-center">
                    <div class="column column-left">
                        <label>Pickup date</label>
                    </div>
                    <div class="column column-right">
                        <input type="text" value="{ formatDate($app.transfer.date_at, 'd-m-y') } { $app.transfer.pickup_time }"
                               disabled>
                    </div>
                </div>
            {/if}
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.hotel }</label>
                </div>
                <div class="column column-right">
                    {#if $app.transfer && $app.transfer.hotel != null}
                        <input type="text" value="{ $app.transfer.hotel.name }" disabled>
                    {:else}
                        <HotelAutoComplete bind:value="{ $app.hotel_name  }" showErrors={ false }/>
                    {/if}
                </div>
            </div>
            {#if $app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER}
                <div class="row form-group align-items-center">
                    <div class="column column-left">
                        <label>Room Number</label>
                    </div>
                    <div class="column column-right">
                        <input type="text" style="max-width: 80px;" bind:value="{ $app.hotel_room }">
                    </div>
                </div>
            {/if}
        {/if}

        <hr>
        <h4>{ $app.lang.payment_options }</h4>
        <div class="row">
            <div class="column payment-column">
                <p class="mt-none"><strong>{ $app.lang.method_paypal }</strong></p>
                <div class="payment-option" on:click={ () => setPaymentMethod(PAYMENT_GATEWAYS.PAYPAL) }>
                    <div class="radio">
                        {#if $app.payment_gateway_id == PAYMENT_GATEWAYS.PAYPAL}
                            <div class="radio-checked"></div>
                        {/if}
                    </div>
                    <img class="radio-image" src="/app/assets/images/icon-paypal.png" alt="PayPal"
                         title="PayPal">
                </div>
                <hr class="hidde-on-large">
            </div>
            <div class="column payment-column">
                <p class="mt-none"><strong>{ $app.lang.method_credit_card }</strong></p>
                <div class="payment-option" on:click={ () => setPaymentMethod(PAYMENT_GATEWAYS.CARDLINK) }>
                    <div class="radio">
                        {#if $app.payment_gateway_id == PAYMENT_GATEWAYS.CARDLINK}
                            <div class="radio-checked"></div>
                        {/if}
                    </div>
                    <img class="radio-image" src="/app/assets/images/icon-credit-cards.png" alt="Credit Cards"
                         title="Credit Cards">
                </div>
            </div>
        </div>
        <hr>

        <div class="bank-info ec-element"
             class:collapsed={ $app.payment_gateway_id != PAYMENT_GATEWAYS.CARDLINK }>
            <p>
                { $app.lang.billing_message }
            </p>
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.country }</label>
                </div>
                <div class="column column-right">
                    <select bind:value="{ $app.bill_info_json.country }">
                        {#each countries as country}
                            <option value="{ country.id }">{ country.name}</option>
                        {/each}
                    </select>
                </div>
            </div>
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.state }</label>
                </div>
                <div class="column column-right">
                    <input type="text" bind:value="{ $app.bill_info_json.state }"
                           required="{ $app.payment_gateway_id == PAYMENT_GATEWAYS.CARDLINK }">
                </div>
            </div>
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.city }</label>
                </div>
                <div class="column column-right">
                    <input type="text" bind:value="{ $app.bill_info_json.city }">
                </div>
            </div>
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.zip }</label>
                </div>
                <div class="column column-right">
                    <input type="text" style="max-width: 50%;"
                           bind:value="{ $app.bill_info_json.zip }">
                </div>
            </div>
            <div class="row form-group align-items-center">
                <div class="column column-left">
                    <label>{ $app.lang.address }</label>
                </div>
                <div class="column column-right">
                    <input type="text" bind:value="{ $app.bill_info_json.address }">
                </div>
            </div>
            <hr>
        </div>

        <div class="totals text-right color-primary mb-lg">
            { $app.lang.total }: <strong>{ $app.totalPrice.toFixed(2) }</strong> €
        </div>

        <div class="mb-md">
            <input type="checkbox" id="terms" bind:checked={ termsChecked } required>
            <label class="checkbox-label" for="terms">{ $app.lang.terms_checkbox}</label>
            <a href="{ $app.lang.terms_link }" class="terms-link"
               target="_blank">{ $app.lang.terms_and_conditions }</a>
            &
            <a href="{ $app.lang.privacy_policy_link }" class="terms-link"
               target="_blank">{ $app.lang.privacy_policy }</a>
        </div>

        <div class="marketing-permissions">
            <p>
                <input type="checkbox" id="marketing-permission" bind:checked={ marketingPermissionsChecked }>
                <label
                        class="checkbox-label"
                        for="marketing-permission">{ $app.lang.marketing.permissions_label}</label>
                <span class="what-to-expect"
                      on:click={ () => showWhatToExpect = !showWhatToExpect }>{ $app.lang.marketing.what_to_expect }</span>
            </p>
            <p>
                { $app.lang.marketing.consent_title }
            </p>
            {#if showWhatToExpect}
                <p style="line-height: 1.5;">
                    {@html $app.lang.marketing.consent_text }
                </p>
            {/if}
        </div>

        <div class="row buttons-row">
            <div class="col-6">
                <Button label="{ $app.lang.btnBack }" type="back" block preventDefault
                        on:click={ () => $app.cartStep = 2 }/>
            </div>
            <div class="column column-right">
                <Button label="{ $app.lang.btnPayment }" type="next" block on:click={ submitTransaction }/>
            </div>
        </div>
    </form>
</div>
<Loader {loading}/>

<form id="route" method="get" action="{ routeFormAction }">
    <input type="hidden" name="ref" value="{ referenceId }"/>
</form>

<style>
    .form-group .column-left {
        width: 100%;
    }

    .form-group .column-right {
        width: 100%;
    }

    .payment-column {
        width: 100%;
    }

    .payment-option {
        cursor: pointer;
    }

    .payment-option .radio {
        position: relative;
        float: left;
        width: 20px;
        height: 20px;
        border: 1px solid #173091;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 50%;
    }

    .payment-option .radio .radio-checked {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: #00a1cb;
        border-radius: 50%;
    }

    .marketing-permissions {
        background: #f6f6f6;
        border-radius: 4px;
        padding: 10px 10px;
        margin-left: -10px;
    }

    .what-to-expect {
        display: block;
        color: #23a8e0;
        margin-top: 20px;
        cursor: pointer;
    }

    .terms-link {
        color: #23a8e0;
    }

    .totals {
        font-size: 31px;
    }

    .totals strong {
        font-size: 45px;
    }

    .bank-info.ec-element {
        max-height: 500px;
    }

    @media (max-width: 375px) {
        .payment-option img {
            height: 38px;
        }
    }

    @media (min-width: 576px) {
        .form-group .column-left {
            width: 40%;
        }

        .form-group .column-right {
            width: 60%;
        }

        .payment-column {
            width: 50%;
        }

        .what-to-expect {
            display: inline-block;
            margin-left: 20px;
            margin-top: unset;
        }
    }
</style>