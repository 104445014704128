<script>
    import {createEventDispatcher} from "svelte/internal";

    export let date = {};
    const dispatch = createEventDispatcher();

    const clickDate = () => {
        dispatch("date-click", date);
    };
</script>


<div class="calendar-cell a-calendar-day {date.classList.join(' ')}"
     class:past={date.past}
     on:click={clickDate}
>
    <span>{date.dateNumber}</span>
</div>


<style>
    .a-calendar-day {
        background: #fff;
        color: #000;
        margin: 1px;
        cursor: pointer;
    }

    .a-calendar-day:hover {
        color: #fff;
        background: #00a1cb;
    }

    .selected {
        border: 1px solid red;
        color: #fff;
        background: #173091;
    }

    .past {
        pointer-events: none;
        background: #ddd;
        color: #999;
    }

    .is-today {
        font-weight: bold;
        background: bisque;
    }

    .disabled, .a-calendar-day.disabled:hover {
        color: #999;
        background: #f9f9f9;
        cursor: default;
    }
</style>