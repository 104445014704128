<script>
    import app from '../stores/app';

    let title = '';
    let captions = [];

    const initialize = () => {
        title = $app.lang.cart_title;
        captions = $app.lang.cart_steps;
    }

    initialize();
</script>

<div class="tickets-top">
    <div class="container">
        <h1 class="text-center">{ title }</h1>
        {#if $app.cartStep > 0}
            <div class="row cart-steps align-items-center">
                {#each captions as caption, index}
                    <div class="col-4 step" class:active={ index + 1 == $app.cartStep }>
                        <div class="circle">
                            <div class="number">
                                { index + 1 }
                            </div>
                        </div>
                        <div class="caption">{ caption }</div>
                    </div>
                {/each}
            </div>
        {/if}
    </div>
    <img src="/app/assets/images/tickets-wave-background.png" class="img-responsive waves" alt="waves">
</div>

<style>
    .cart-steps {
        font-size: 18px;
        color: #afafaf;
        margin-bottom: 30px;
    }

    .cart-steps .step {
        display: flex;
        flex-wrap: wrap;
        align-items: center !important;
        justify-content: center;
    }

    .cart-steps .step .circle {
        flex: 0 0 auto;
        position: relative;
        width: 35px;
        height: 35px;
        background: #afafaf;
        border-radius: 50%;
    }

    .cart-steps .step .circle .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        color: #fff;
    }

    .cart-steps .step .caption {
        flex: 0 0 auto;
        letter-spacing: -0.9px;
        padding-left: 5px;
    }

    .cart-steps .step.active {
        color: #173091;
    }

    .cart-steps .step.active .circle {
        background: #fcb040;
    }

    @media (max-width: 375px) {
        .cart-steps {
            font-size: 16px;
        }

        .cart-steps .step .circle {
            width: 30px;
            height: 30px;
        }

        .cart-steps .step.col-3 {
            padding: 0 5px;
        }

        /*.cart-steps .step .caption {*/
        /*    padding-left: 15px;*/
        /*}*/
    }

    @media (min-width: 576px) {
        .cart-steps {
            font-size: 27px;
        }

        .cart-steps .step .circle {
            width: 60px;
            height: 60px;
        }

        .cart-steps .step .caption {
            padding-left: 15px;
        }
    }
</style>