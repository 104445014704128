<script>
    import app from '../stores/app';
    import R from "./../lib/requests";
    import {TRANSFER_TYPE} from "../lib/constants";
    import {dateAfter, setTransferStartDate, formatDate, scrollToElement, elementExist} from '../lib/lib';
    import appAlert from "../stores/appAlert";
    import FindHotelComponent from "./FindHotelComponent.svelte";
    import FindLocationComponent from "./FindLocationComponent.svelte";
    import DatePicker from "../components/datepicker/DatePicker.svelte";
    import Icon from "../components/Icon.svelte";
    import Button from "../controls/Button.svelte";
    import Loader from "../components/Loader.svelte";

    export let cartView;
    export let completeCallback;
    export let cancelCallback;

    let hotel = null;
    let pickupPoint = null;
    let pickupRoutes = [];
    let selectedRoute = null;
    let selectedDate = null;
    let selectedTime = null;
    let startingDate = null;
    let validDates = [];
    let noRoutesAvailable = false;
    let mapLink = '';
    let loading = false;
    let view = 'find-hotel';

    let adultPrice = 0;
    let childPrice = 0;
    let infantPrice = 0;


    // set the selected route according to date
    const setSelectedRouteDateAndTime = () => {
        if (selectedDate) {
            selectedRoute = pickupRoutes.find(x => x.date_at === selectedDate);
        }
        if (!selectedRoute) {
            if (pickupRoutes.length) {
                selectedRoute = pickupRoutes[0];
                selectedDate = selectedRoute.date_at;
            }
        }

        if (selectedRoute && selectedRoute.pickup_times.length && !selectedRoute.pickup_times.find(x => x.pickup_time == selectedTime)) {
            selectedTime = selectedRoute.pickup_times[0].pickup_time;
        }
    };


    const getPickupPointRoutes = () => {

        const onSuccess = result => {
            loading = false;

            if (!result || !result.length) {
                pickupRoutes = null;
                return;
            }

            // sort routes by date
            result.sort((a, b) => (a.date_at > b.date_at) ? 1 : ((b.date_at > a.date_at) ? -1 : 0));

            pickupRoutes = [];

            // add routes from result in pickup point routes if not exist
            result.map(item => {
                // keep only routes with valid dates
                if (item.date_at >= startingDate && item.date_at <= $app.settings.dates.closing_date && !$app.settings.dates.closed_dates_json.includes(item.date_at)) {

                    // check if route date exist
                    let route = pickupRoutes.find(x => x.date_at === item.date_at);
                    if (route) {
                        route.pickup_times.push({
                            pickup_time: item.pickup_time,
                            zone_id: item.zone_id,
                        });

                        // times must be displayed sorted on select control
                        route.pickup_times.sort((a, b) => (a.pickup_time > b.pickup_time) ? 1 : ((b.pickup_time > a.pickup_time) ? -1 : 0));
                    } else {
                        route = {
                            date_at: item.date_at,
                            pickup_times: [{
                                pickup_time: item.pickup_time,
                                zone_id: item.zone_id,
                            }]
                        }

                        pickupRoutes.push(route);
                    }
                }
            });

            // set the selected route and selected date
            setSelectedRouteDateAndTime();

            // set the valid dates for datePicker
            validDates = [];
            pickupRoutes.map(route => {
                validDates = [...validDates, route.date_at];
            });
            // no routes available if no valid dates!
            noRoutesAvailable = validDates.length == 0;

            mapLink = '';
            if (hotel) {
                if (hotel.lat && hotel.lng && pickupPoint.lat && pickupPoint.lng) {
                    mapLink = `https://www.google.com/maps/dir/'${hotel.lat},${hotel.lng}'/'${pickupPoint.lat},${pickupPoint.lng}'`;
                }
            }

            if (elementExist("findHotel")) {
                // If view is "find your hotel"
                scrollToElement("findHotel");
            } else {
                // if view is points on google map
                // if ($app.device === 'sm' || $app.device === 'xs') {
                scrollToElement("selectDateTime");
                // }
            }
        }

        const onFailure = err => {
            loading = false;
            noRoutesAvailable = true;
            console.log("ERROR!");
        }

        noRoutesAvailable = false;
        mapLink = '';
        initializeDates();
        let dt = new Date();

        loading = true;
        R.handle(R.pickupPoint.yearly, {id: pickupPoint.id, year: dt.getFullYear()}, onSuccess, onFailure);
    };

    const getPickupPointPrices = () => {
        if (pickupPoint.prices_json && pickupPoint.prices_json.transfer) {
            adultPrice = pickupPoint.prices_json.transfer.ADULT;
            adultPrice = pickupPoint.prices_json.transfer.ADULT;
        }
    }

    const onHotelChange = ({detail}) => {
        if (!hotel || hotel.id != detail.id) {
            hotel = detail;
            pickupPoint = hotel.pickup_point;
            clear();
            getPickupPointPrices();
            getPickupPointRoutes();
        }
    }

    const onCantFindHotel = () => {
        hotel = null;
        pickupPoint = null;
        view = 'find-location';
    }

    const onPickupChange = ({detail}) => {
        if (!pickupPoint || pickupPoint.id != detail.id) {
            pickupPoint = detail;
            clear();
            getPickupPointPrices();
            getPickupPointRoutes();
        }
    }

    const onDateChange = ({detail}) => {
        // get the route for date and set the times
        setSelectedRouteDateAndTime();
    };

    const clear = () => {
        selectedRoute = null;
        adultPrice = 0;
        childPrice = 0;
        infantPrice = 0;
    }

    const initializeDates = () => {
        // let dt = new Date();
        //
        // let time = formatDate(dt, 'H:s');
        // if (time > '22:00') {
        //     // if time is passed 10 then transfer starts the day after next date
        //     startingDate = dateAfter("today", 2);
        // } else {
        //     // transfer starts next date
        //     startingDate = dateAfter("today", 1);
        // }
        // if (startingDate < $app.settings.dates.opening_date) {
        //     startingDate = $app.settings.dates.opening_date;
        // }

        startingDate = setTransferStartDate($app.settings.dates);

        if (selectedDate && selectedDate < startingDate) {
            selectedDate = startingDate;
        }
    }

    const gotoStart = () => {
        $app.transfer_type_id = TRANSFER_TYPE.NO_TRANSFER;
        $app.transfer = null;
        cancelCallback();
    }

    const gotoTickets = () => {

        // get selected route according to selected date
        let route = pickupRoutes.find(x => x.date_at === selectedDate);
        if (!route) {
            appAlert.error(`No route found for pickup point ${pickupPoint.name}!`);
            return;
        }

        let pickupTime = route.pickup_times.find(x => x.pickup_time == selectedTime);
        if (!pickupTime) {
            appAlert.error(`The selected time is not valid for the pickup point ${pickupPoint.name}!`);
            return;
        }

        // make the transfer data
        $app.transfer_type_id = TRANSFER_TYPE.BUS_TRANSFER;
        $app.transfer = {
            hotel: null,
            pickup_point: {
                id: pickupPoint.id,
                name: pickupPoint.name,
                lat: pickupPoint.lat,
                lng: pickupPoint.lng,
            },
            prices: pickupPoint.prices_json.transfer,
            date_at: selectedDate,
            pickup_time: selectedTime,
            zone_id: pickupTime.zone_id,
        }

        if (hotel) {
            $app.transfer.hotel = {
                id: hotel.id,
                name: hotel.name,
                area: hotel.area.name,
                lat: hotel.lat,
                lng: hotel.lng,
            }
        }

        completeCallback();
    }

    // $:{
    //     console.log("point", pickupPoint);
    // }
</script>
<div class="container mt-lg">
    {#if view === 'find-hotel'}
        <FindHotelComponent on:change={ onHotelChange } on:cant_find_hotel={ onCantFindHotel }/>
    {:else}
        <FindLocationComponent on:change={ onPickupChange }/>
    {/if}

    <div id="selectDateTime">
        {#if pickupPoint !== null}
            <div class="row">
                <div class="column col-12 transfer-info mb-md">
                    {#if hotel}
                        <p class="transfer-row">
                            <span class="transfer-label">Your Hotel:</span> <span
                                class="transfer-data">{ hotel.name }</span>
                        </p>
                        <p class="transfer-row">
                            <span class="transfer-label">Location:</span> <span
                                class="transfer-data">{ hotel.area.name }</span>
                        </p>
                    {/if}
                    <p class="transfer-row">
                        <span class="transfer-label">Your Pickup Point:</span>
                        <span class="transfer-data">{ pickupPoint.name }
                            {#if mapLink}
                            <a href="{ mapLink }" target="_blank" class="map-button" title="view on google maps">
                                <Icon size="16" icon="map"/>
                            </a>
                        {/if}
                    </span>
                    </p>
                    <hr>
                    {#if noRoutesAvailable}
                        {#if hotel != null}
                            <p class="text-big color-danger">
                                There are no departure dates for this hotel!<br>
                            </p>
                        {:else}
                            <p class="text-big color-danger">
                                There are no departure dates for this pickup point!<br>
                            </p>
                        {/if}
                    {:else}
                        <!-- BUS TRANSFER PRICES -->
                        <!--{#if pickupPoint.prices_json && pickupPoint.prices_json.transfer}-->
                        <!--    <p class="transfer-row">-->
                        <!--        <span class="transfer-label">1 x Adult:</span>-->
                        <!--        <span class="transfer-data">{ pickupPoint.prices_json.transfer.ADULT } €</span>-->
                        <!--    </p>-->
                        <!--    <p class="transfer-row">-->
                        <!--        <span class="transfer-label">1 x Child:</span>-->
                        <!--        <span class="transfer-data">{ pickupPoint.prices_json.transfer.CHILD } €</span>-->
                        <!--    </p>-->
                        <!--    <p class="transfer-row">-->
                        <!--        <span class="transfer-label">1 x Toddler:</span>-->
                        <!--        <span class="transfer-data">Free</span>-->
                        <!--    </p>-->
                        <!--{/if}-->
                        <p>
                            Your instructions will be sent via e-mail
                        </p>
                    {/if}
                </div>
            </div>
            {#if selectedRoute}
                <div class="row">
                    <div class="column col-6 col-xs-12">
                        <div class="form-group">
                            <DatePicker startDate="{ startingDate }"
                                        {validDates}
                                        bind:value="{ selectedDate }"
                                        on:change={ onDateChange }
                            />
                        </div>
                    </div>
                    <div class="column col-6 col-xs-12">
                        <div class="form-group">
                            <label>TIME</label>
                            <select class="form-control" bind:value="{ selectedTime }">
                                {#each selectedRoute.pickup_times as time}
                                    <option value="{ time.pickup_time }">{ time.pickup_time }</option>
                                {/each}
                            </select>
                        </div>
                    </div>
                </div>
            {/if}
        {/if}
    </div>

    <div class="row buttons-row">
        <div class="column hidden-sm col-4">
            <Button label="BACK" type="back" block on:click={ gotoStart }/>
        </div>
        <div class="column col-8">
            {#if pickupPoint && selectedDate && selectedTime}
                <Button label="Choose Your Tickets" type="next" block on:click={ gotoTickets }/>
            {/if}
        </div>
    </div>
</div>

<Loader {loading}/>

<style>
    .transfer-info {
    }

    .transfer-info hr {
        width: 50%;
        border-color: #173091;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .transfer-row {
    }

    .transfer-label {
        display: block;
        font-size: 17px;
        font-weight: bold;
        color: #173091;
    }

    .transfer-data {
        display: block;
        font-weight: bold;
    }

    .map-button {
        padding: 2px 5px;
        background: #fff;
        display: inline-block;
        border-radius: 4px;
        margin-left: 10px;
    }

    @media (min-width: 576px) {
        .transfer-label {
            display: inline-block;
            padding-right: 10px;
        }

        .transfer-data {
            display: inline-block;
        }
    }
</style>