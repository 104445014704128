<script>
    import {createEventDispatcher} from 'svelte/internal';

    export let name = null;
    export let label = '';
    export let value = null;
    export let title = '';
    export let checked = 0;
    export let disabled = false;
    export let editable = true;
    export let style = '';
    export let hideOverflow = null;
    export let inline = null;
    export let block = null;

    const dispatch = createEventDispatcher();


    const onClick = () => {
        if (!disabled && editable) {
            checked = !checked;
            dispatch('change', {name, value, checked});
        }
    }

    $: checked = ~~checked;
</script>

<div class="checkbox" class:block={ block } on:click="{ onClick }">
    <div class="square">
        <div class="icon">
            {#if checked}
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 45 45" fill="#fcb042" xml:space="preserve"
                     stroke="#fcb042" stroke-width="4">
                <path d="M1.36,17.427c0,0,7.311-0.122,10.844,8.163c0,0,15.474-22.175,31.435-18.885c0,0-17.789,7.067-32.045,31.922L1.36,17.427z"
                />
                </svg>
            {/if}
        </div>
    </div>
    {#if label}
        <div class="label" class:hide-overflow={ hideOverflow }>{ label }</div>
    {/if}
</div>

<style>
    .checkbox {
        cursor: pointer;
    }

    .checkbox:after {
        content: "";
        clear: both;
        display: table
    }

    .square {
        position: relative;
        float: left;
        width: 22px;
        height: 22px;
        border: 2px solid #54585b;
        border-radius: 3px;
    }

    .icon {
        position: absolute;
        bottom: -9px;
        right: -5px;
        width: 23px;
        height: 26px;
    }


    .checkbox .label {
        float: left;
        padding-left: 5px;
    }

    .checkbox.disabled, .checkbox.disabled .icon {
        color: #888;
        cursor: not-allowed;
    }

    .hide-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .inline {
        display: inline-block;
        white-space: nowrap;
        margin-left: 10px;
    }

    .inline + .inline {
        margin-top: 0;
        margin-left: 10px;
    }

    .block {
        display: block;
    }

    @media (min-width: 576px) {
        .checkbox {
            margin-bottom: 10px;
        }

        .square {

        }

        .icon {

        }

        .caption {
            font-size: 25px;
        }
    }
</style>
