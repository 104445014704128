<script>
    import app from "../../stores/app";
    import {dateAfter, formatDate} from '../../lib/lib';
    import Button from "../../controls/Button.svelte";
    import Product from "./Product.svelte";
    import CheckboxSquare from "../../controls/CheckboxSquare.svelte";
    import {TRANSFER_TYPE, TICKET_CODES, CLASS_CODES} from "../../lib/constants";

    export let cancelCallback;

    const onPrivateTransferToggle = () => {
        if ($app.transfer_type_id != TRANSFER_TYPE.BUS_TRANSFER) {
            $app.transfer_type_id = $app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER ? TRANSFER_TYPE.PRIVATE_TRANSFER : TRANSFER_TYPE.NO_TRANSFER;
        }
    }

    const setProductEntranceDate = (code) => {
        let today = new Date();
        let startDate = formatDate(today, 'y-m-d');
        if (code == CLASS_CODES.DISCOUNTED) {
            // Discounted tickets are valid on next day if time is grater then 10:00
            let nowTime = formatDate(today, 'H:i');
            if (nowTime > "10:00") {
                startDate = dateAfter('today', 1);
            }
        }

        // check opening date
        if (startDate < $app.settings.dates.opening_date) {
            startDate = $app.settings.dates.opening_date;
        }

        // check closing date
        if (startDate > $app.settings.dates.closing_date) {
            startDate = $app.settings.dates.closing_date;
        }

        // check closed dates
        $app.settings.dates.closed_dates_json.map(date => {
            if (startDate == date) {
                startDate = dateAfter(date, 1);
            }
        });

        if (startDate === formatDate(today, 'y-m-d')) {
            return "today";
        }

        return formatDate(startDate, 'd-m-y');
    }

    const initialize = () => {

        let TICKETS_PRICE = {};
        $app.settings.tickets.map(item => {
            TICKETS_PRICE[item.code] = item.price;
        });

        // set product prices and rules
        let product;
        let flexyEntranceDate = setProductEntranceDate(CLASS_CODES.FLEXY);
        let discountedEntranceDate = setProductEntranceDate(CLASS_CODES.DISCOUNTED);

        // ADULT PRODUCT
        product = $app.products.find(x => x.code === TICKET_CODES.ADULT);

        // Flexy class for adult product
        let flexy = product.classes.find(x => x.code === CLASS_CODES.FLEXY);
        flexy.rules = [
            "Refundable",
            "Includes <span class='important'>2.95€</span> credit to spend inside the park's bars and restaurants",
            "Can play all slides",
        ];
        if ($app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER) {
            flexy.rules.unshift(`<span class="important">Valid from ${flexyEntranceDate}</span>`);
        }
        // If bus transfer then Flexy class has original ticket prices
        if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
            flexy.price = TICKETS_PRICE.ADULT;
        }

        // Discounted class for adult product
        let discounted = product.classes.find(x => x.code === CLASS_CODES.DISCOUNTED);
        discounted.rules = [
            "Non Refundable",
            "Can play all slides"
        ];
        if ($app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER) {
            discounted.rules.unshift(`<span class="important">Valid from ${discountedEntranceDate}</span>`);
        }
        discounted.is_active = $app.transfer == null;


        // CHILD PRODUCT
        product = $app.products.find(x => x.code === TICKET_CODES.CHILD);
        // Flexy class for child product
        flexy = product.classes.find(x => x.code === CLASS_CODES.FLEXY);
        flexy.rules = [
            "Refundable",
            "Includes <span class='important'>1.95€</span> credit to spend inside the park's bars and restaurants",
            "Can play Kids and Advanced slides",
        ];
        if ($app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER) {
            flexy.rules.unshift(`Valid from ${flexyEntranceDate}`);
        } else if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
            flexy.price = TICKETS_PRICE.CHILD;
        }

        // Discounted class for child product
        discounted = product.classes.find(x => x.code === CLASS_CODES.DISCOUNTED);
        discounted.rules = [
            "Non Refundable",
            "Can play Kids and Advanced slides",
        ];
        if ($app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER) {
            discounted.rules.unshift(`Valid from ${discountedEntranceDate}`);
        }
        discounted.is_active = $app.transfer_type_id == TRANSFER_TYPE.NO_TRANSFER;
    };


    initialize();
</script>

<div class="container mt-lg">
    <h2 class="text-center mt-none">{ $app.lang.choose_your_tickets }</h2>
    {#each $app.products as product}
        <Product bind:product={ product }/>
        <hr>
    {/each}
    <p style="margin-top: -20px;">Warning! Access to water games based on the height.</p>

    <!-- Display request for private transfer only if not bus transfer -->
    <!--{#if $app.transfer_type_id != TRANSFER_TYPE.BUS_TRANSFER}-->
    <!--    <div class="row private-transfer-request mt-xl">-->
    <!--        <div class="column left-column">-->
    <!--            <h4 class="title mt-none mb-none" on:click={ onPrivateTransferToggle }>Request for private transfer</h4>-->
    <!--            <p class="mt-xs">-->
    <!--                We can arrange your transfer by taxi or mini bus. Further information will be send via email.-->
    <!--            </p>-->
    <!--        </div>-->
    <!--        <div class="column right-column">-->
    <!--            <CheckboxSquare block checked={ $app.transfer_type_id == TRANSFER_TYPE.PRIVATE_TRANSFER }-->
    <!--                            on:change={ onPrivateTransferToggle }/>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--{/if}-->

    <div class="row buttons-row">
        <div class="col-6">
            <Button label="{ $app.lang.btnBack }" type="back" block on:click={ () => cancelCallback() }/>
        </div>
        <div class="col-6">
            <Button label="{ $app.lang.btnNext }" type="next" block on:click={ () => $app.cartStep = 2 }/>
        </div>
    </div>
    <div class="how-to-pay">
        <div class="cards">
            <img src="/app/assets/images/how-to-pay.png" alt="how to pay">
        </div>
    </div>
</div>

<style>
    .private-transfer-request {

    }

    .private-transfer-request .title {
        cursor: pointer;
    }

    .private-transfer-request .left-column {
        width: calc(100% - 45px);
    }

    .private-transfer-request .right-column {

    }

    @media (min-width: 576px) {
        .private-transfer-request .left-column {
            width: calc(100% - 60px);
        }
    }
</style>

