<script>
    export let loading = false;
    export let size = 80;
</script>

{#if loading}
    <div class="loader-overlay">
        <div class="loader">
            <img src="/app/assets/images/loader.gif" style="width:{ size }px; height:{ size }px;"/>
        </div>
    </div>
{/if}

<style>
    .loader-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.30);
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        background: #fff;
        border-radius: 50%;
    }

    .loader img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 80px;
    }
</style>