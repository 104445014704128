<script>
    import R from "./../lib/requests";
    import {clickOutside} from '../lib/clickOutside.js';
    import IconControlLoading from "./IconControlLoading.svelte";

    export let hotel = null;
    export let disabled = false;
    export let value = '';
    export let required = false;
    export let showErrors = true;
    export let onSelectCallback = null;


    // let value = '';
    // let hotel = null;
    let pickupPoint = null;
    let hotelResults = null;
    let loading = false;
    let timer;

    const onHotelInput = event => {
        if (event.key === 'Escape') {
            if (hotelResults != null) {
                closeDropdown();
            } else {
                if (hotel) {
                    value = hotel.name;
                } else {
                    value = '';
                }
                clearHotelSearch();
            }
            return;
        }

        if ((event.key === 'Backspace' || event.key === 'Delete') && value === '') {
            hotel = null;
            pickupPoint = null;
            clearHotelSearch();
        }
        if (value.length > 2) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(searchHotel, 300);
        }
    };

    const searchHotel = () => {
        loading = true;

        const onSuccess = result => {
            loading = false;
            hotelResults = result;
        };

        const onFailure = err => {
            loading = false;
        };

        R.handle(R.hotel.search, {q: value}, onSuccess, onFailure);
    };

    const clearHotelSearch = () => {
        hotelResults = null;
    };

    const selectHotel = (selectedHotel) => {
        hotel = selectedHotel;
        value = selectedHotel.name;
        if (onSelectCallback != null) {
            onSelectCallback(hotel);
        }
        clearHotelSearch();
    }

    const closeDropdown = () => {
        clearHotelSearch();
    }

    if (hotel) {
        value = hotel.name;
    }
</script>

<div class="transfer-hotel-input">
    <input type="text" bind:value="{ value }" placeholder="type your hotel name" on:keyup={ onHotelInput }
           { disabled } {required}>
    <IconControlLoading {loading}/>
    {#if hotelResults}
        {#if hotelResults.length}
            <div class="hotels-dropdown" use:clickOutside on:click_outside="{ closeDropdown }">
                <ul>
                    {#each hotelResults as hotel, index}
                        {#if index < 20}
                            <li>
                                <div class="hotel-option" on:click={ () => selectHotel(hotel) }>
                                    <div class="name">{ hotel.name }</div>
                                    <div class="area">{ hotel.area.name }</div>
                                </div>
                            </li>
                        {/if}
                    {/each}
                    {#if hotelResults.length > 20}
                        <li>
                            <div class="too-manu-results color-danger">
                                too many results. the first 20 appear.
                            </div>
                        </li>
                    {/if}
                </ul>
            </div>
        {:else}
            {#if showErrors}
                <p class="no-results text-small color-danger">
                    No hotels found for "{ value }"!
                </p>
            {/if}
        {/if}
    {/if}
</div>


<style>
    /*.transfer-hotel {*/
    /*    margin-bottom: 40px;*/
    /*}*/

    .transfer-hotel-input {
        position: relative;
    }

    .hotels-dropdown {
        position: absolute;
        top: 36px;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 13px;
        box-shadow: inset 0 2px 4px #cecece;
        padding: 10px 10px 10px 10px;
        z-index: 1000;
    }

    .hotels-dropdown ul {
        list-style: unset;
        padding: 0;
        margin: 0;
    }

    .hotels-dropdown ul > li {
        display: block;
        margin: 1px 0;
    }

    .hotels-dropdown ul > li .hotel-option {
        padding: 5px 5px;
        cursor: pointer;
    }
    .hotels-dropdown ul > li .hotel-option .name {
        color: #383838;
    }
    .hotels-dropdown ul > li .hotel-option .area {
        color: #979797;
        font-size: 14px;
    }

    .hotels-dropdown ul > li .hotel-option:hover {
        color: #fff;
        background: #00a1cb;
    }
    .hotels-dropdown ul > li .hotel-option:hover .name {
        color: #fff;
    }
    .hotels-dropdown ul > li .hotel-option:hover .area {
        color: #fff;
    }

    .hotels-dropdown ul > li .too-manu-results {
        color: #fff;
        background: #bf1e2e;
        padding: 5px 5px;
        margin-top: 5px;
    }

    .no-results {
        margin-top: 5px;
        padding: 0 7px;
    }

    @media (min-width: 576px) {
        .hotels-dropdown {
            border-radius: 2px;
            box-shadow: 0 2px 4px #cecece;
        }
    }
</style>