<script>
    import app from "../stores/app";
    import {onMount} from 'svelte';
    import {createEventDispatcher} from "svelte/internal";
    import {scrollToElement} from "../lib/lib";
    import R from "../lib/requests";
    import AppAlert from "../stores/appAlert";

    let pickupPoints = [];
    let pickupPoint = null;
    let loading = false;

    let mapContainer;
    let map;
    let directionsService;
    let directionsRenderer;
    let mapZoom = 8.60;
    let myLocation = null;
    let markers = [];
    let mapError = false;
    let mapInitialized = false;
    let mapTimer = null;

    const dispatch = createEventDispatcher();

    const loadAllPickupPoints = () => {
        const onSuccess = result => {
            loading = false;
            let indexes = [];
            // nearby = result;
            // add nearby in pickup points
            result.map(item => {
                if (item.pickup_point_id && indexes.indexOf(item.pickup_point_id) < 0) {
                    pickupPoints.push(item.pickup_point);
                    indexes.push(item.pickup_point_id);
                }
            });

            addMarkers();
        };

        const onFailure = err => {
            loading = false;
            console.log("ERROR", err);
        };

        loading = true;
        R.handle(R.hotel.index, [], onSuccess, onFailure);
    }

    const loadNearby = () => {
        const onSuccess = result => {
            loading = false;
            // nearby = result;
            // add nearby in pickup points
            result.map(item => {
                if (!pickupPoints.find(x => x.id == item.id)) {
                    pickupPoints.push(item);
                }
            });

            addMarkers();
        };

        const onFailure = err => {
            loading = false;
            console.log("ERROR", err);
        };

        let data = {
            lat_lng: `${myLocation.lat},${myLocation.lng}`
        }

        loading = true;
        R.handle(R.pickupPoint.nearby, data, onSuccess, onFailure);
    }

    const geoFindMe = () => {

        function success(position) {
            myLocation = {lat: position.coords.latitude, lng: position.coords.longitude};
            mapZoom = 14;

            map.setCenter(myLocation);
            map.setZoom(mapZoom);
            if ($app.device === 'sm' || $app.device === 'xs') {
                scrollToElement('find-my-location');
            }
        }

        function error(err) {
            AppAlert.error("Could not submit your Geolocation!");
        }

        if (!navigator.geolocation) {
            AppAlert.error("Geolocation is not supported by your browser");
        } else {
            navigator.geolocation.getCurrentPosition(success, error);
        }
    }


    const addMarkers = () => {
        // add new markers
        pickupPoints.map(item => {
            let pin = markers.find(x => x.id == item.id);

            if (!pin) {
                item.lat = parseFloat(item.lat);
                item.lng = parseFloat(item.lng);

                pin = {
                    id: item.id,
                    open: false,
                };
                pin.marker = new google.maps.Marker({
                    map,
                    position: {lat: item.lat, lng: item.lng},
                    title: item.name,
                });
                // pin.marker.set("pickup_point_id", item.id);
                pin.infoWindow = new google.maps.InfoWindow({
                    content: `<div class="info-window"><div class="title">${item.name}</div><button class="pin-button" onclick="infoClick(${item.id})">Choose this</button></div>`,
                    ariaLabel: item.name,
                });
                pin.marker.addListener("click", (e) => {
                    if (!pin.open) {
                        closeInfoWindows();
                        pin.infoWindow.open({
                            anchor: pin.marker,
                            map,
                        });
                        pin.open = true;
                    } else {
                        pin.infoWindow.close();
                        pin.open = false;
                    }

                    // let point = pickupPoints.find(x => x.id == pin.marker.get('pickup_point_id'));
                    // if (point && (!pickupPoint || pickupPoint.id != point.id)) {
                    //     pickupPoint = point;
                    //     dispatch("change", pickupPoint);
                    // }
                });

                markers.push(pin);
            }
        });
    }

    const closeInfoWindows = (exclude_id = 0) => {
        markers.map(pin => {
            if (pin.id != exclude_id && pin.open) {
                pin.infoWindow.close();
                pin.open = false;
            }
        });
    }


    onMount(async () => {
        try {
            // directionsService = new google.maps.DirectionsService();
            // directionsRenderer = new google.maps.DirectionsRenderer();
            map = new google.maps.Map(mapContainer, {
                zoom: mapZoom,
                center: {lat: 35.2927669, lng: 24.9029927},
            });

            // map.addListener('center_changed', evt => {
            //     if (mapTimer) {
            //         clearTimeout(mapTimer);
            //         mapTimer = null;
            //     }
            //
            //     mapTimer = setTimeout(() => {
            //         myLocation = {
            //             lat: map.getCenter().lat(),
            //             lng: map.getCenter().lng()
            //         }
            //         loadNearby();
            //         clearTimeout(mapTimer);
            //         mapTimer = null;
            //     }, 1000);
            //
            // });

            // map.addListener('click', evt => {
            //     console.log("map click");
            // });

            // Handle click on button inside info windows
            document.infoClick = (pickup_id) => {
                let point = pickupPoints.find(x => x.id == pickup_id);
                if (point && (!pickupPoint || pickupPoint.id != point.id)) {
                    pickupPoint = point;
                    dispatch("change", pickupPoint);
                }
            }

            loadAllPickupPoints();

            // directionsRenderer.setMap(map);
        } catch (error) {
            mapError = true;
        }

        if (mapError) {
            return;
        }


        const input = document.getElementById("pac-input");
        const searchBox = new google.maps.places.SearchBox(input);

        searchBox.addListener("places_changed", () => {

            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            } else {
                map.setCenter(places[0].geometry.location);
                map.setZoom(14);
                if ($app.device === 'sm' || $app.device === 'xs') {
                    scrollToElement('find-my-location');
                }
            }
        });
    });

</script>

<h2 class="text-center mt-none">Choose a Pick-up Point</h2>
<p>
    Find your location in the map and click on your nearest pin to select your pick-up point.
</p>
<div class="location-input form-group mb-lg">
    <div class="column-left">
        <input type="text" id="pac-input" placeholder="type your hotel, area or street name">
    </div>
    <div class="column-right">
        <a href="#" class="find-location-button" id="find-my-location" on:click|preventDefault={ geoFindMe }>
            <span class="icon"><img src="/app/assets/images/find-location-icon.png" alt="find location icon"></span>
            <span class="caption">Find my location</span>
        </a>
    </div>
</div>

<div class="map-outer mb-md">
    <div class="map-content" bind:this={mapContainer}>
        {#if mapError}
            <div class="map-error">
                An error occurred on the map.<br>
                If you have installed an Add Blocker, try to disable it and refresh this page.
            </div>
        {/if}
    </div>
    {#if loading}
        <div class="map-loader">Searching for nearby pickup points...</div>
    {/if}
</div>

<style>
    .location-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .location-input .column-left {
        width: calc(100% - 200px);
    }

    .location-input .column-right {

    }

    .find-location-button {
        display: flex;
        align-items: center;
        color: #173091;
        background: #fff;
        border-radius: 45px;
        padding-right: 10px;
    }

    .find-location-button .icon {
        display: block;
        width: 45px;
        height: 45px;
        line-height: 1;
        background: #173091;
        border-radius: 50%;
        padding: 10px;
    }

    .find-location-button .icon img {
        width: 100%;
    }

    .find-location-button .caption {
        padding: 0 10px;
    }

    .map-outer {
        position: relative;
    }

    .map-content {
        width: 100%;
        height: 400px;
        background: #CCC;
    }

    .map-outer .map-loader {
        position: absolute;
        left: 10px;
        bottom: 30px;
        color: #fff;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 4px;
        padding: 10px;
    }

    @media (max-width: 576px) {
        .location-input {
            display: block;
        }

        .location-input .column-left, .location-input .column-right {
            width: 100%;
            margin-bottom: 50px;
        }

        .find-location-button {
            width: max-content;
            margin: 0 auto;
        }

        .map-outer {
            margin-left: -15px;
            margin-right: -15px;
        }

    }

    :global(.info-window) {
        max-width: 140px;
    }

    :global(.info-window .title) {
        font-weight: bold;
        margin-top: 5px;
    }

    :global(.pin-button) {
        display: block;
        width: 100%;
        font-size: 14px;
        color: #fff;
        background: #00a1cb;
        border-radius: 5px;
        border-style: none;
        padding: 7px 10px;
        margin-top: 5px;
        cursor: pointer;
    }

    :global(.pin-button):hover {
        background: #173091;
    }
</style>