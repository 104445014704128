<script>
    import {CART_VIEW} from './lib/constants';
    import app from "./stores/app";
    import {scrollToElement} from "./lib/lib";
    import CartHeader from "./cart/CartHeader.svelte";
    import CartStep1 from "./cart/en/CartStep1.svelte";
    import CartStep2 from "./cart/CartStep2.svelte";
    import CartStep3 from "./cart/CartStep3.svelte";
    import BusTransfer from "./transfer/BusTransfer.svelte";


    let cartView = CART_VIEW.MAIN_VIEW;

    const gotoTickets = () => {
        cartView = CART_VIEW.TICKETS;
        $app.cartStep = 1;
    };

    const initialize = () => {

        let GROUPS = $app.GROUPS;
        let TICKETS = $app.TICKETS;

        // Set default tickets class
        $app.ticket_discount_id = GROUPS.FLEXY.id;

        // set the tickets
        // ADULTS
        let product = {
            id: TICKETS.ADULT.id,
            code: TICKETS.ADULT.code,
            name: "ADULTS",
            admissionTitle: 'General Admission',
            personsLabel: 'No. of adults',
            minPersons: 1,
            maxPersons: 9,
            persons: 1,
            totalPrice: TICKETS.ADULT.price,
            icon: 'adult',
            classes: [
                {
                    id: GROUPS.FLEXY.id,
                    code: 'FLEXY',
                    title: 'Flexy',
                    price: GROUPS.FLEXY.tickets.ADULT.original_price,
                    discounted_price: 0,

                    rules: [],
                    games: ['kids', 'advanced', 'xtreme'],
                    is_active: true,
                },
                {
                    id: GROUPS.DISCOUNTED.id,
                    code: 'DISCOUNTED',
                    title: 'Discounted',
                    price: GROUPS.DISCOUNTED.tickets.ADULT.original_price,
                    discounted_price: GROUPS.DISCOUNTED.tickets.ADULT.price,
                    rules: [],
                    games: ['kids', 'advanced', 'xtreme'],
                    is_active: true,
                }
            ]
        }
        $app.products.push(product);

        // CHILDREN
        product = {
            id: TICKETS.CHILD.id,
            code: TICKETS.CHILD.code,
            name: "KIDS",
            admissionTitle: 'Kids Admission',
            personsLabel: 'No. of kids',
            minPersons: 0,
            maxPersons: 9,
            persons: 0,
            totalPrice: 0,
            icon: 'child',
            classes: [
                {
                    id: GROUPS.FLEXY.id,
                    code: 'FLEXY',
                    title: 'Flexy',
                    price: GROUPS.FLEXY.tickets.CHILD.original_price,
                    discounted_price: 0,
                    rules: [],
                    games: ['kids', 'advanced'],
                    is_active: true,
                },
                {
                    id: GROUPS.DISCOUNTED.id,
                    code: 'DISCOUNTED',
                    title: 'Discounted',
                    price: GROUPS.DISCOUNTED.tickets.CHILD.original_price,
                    discounted_price: GROUPS.DISCOUNTED.tickets.CHILD.price,
                    rules: [],
                    games: ['kids', 'advanced'],
                    is_active: true,
                }
            ]
        }
        $app.products.push(product);

        // TODDLER
        product = {
            id: TICKETS.INFANT.id,
            code: TICKETS.INFANT.code,
            name: "TODDLER",
            admissionTitle: 'Toddler',
            personsLabel: 'No. of toddler',
            minPersons: 0,
            maxPersons: 9,
            persons: 0,
            totalPrice: 0,
            icon: 'toddler',
            classes: [],
            // rules: [],
        }
        $app.products.push(product);
    }

    const gotoHome = () => {
        // reload page to clear tickets
        window.open("/en/", "_self");
    }

    initialize();
</script>

<CartHeader/>
<div class="cart-container">
    {#if cartView == CART_VIEW.MAIN_VIEW}
        <div class="container main-view-container">
            <h2 class="text-center mt-none">Need Transfer?</h2>
            <div class="row">
                <div class="column transfer-column">
                    <button class="transfer-option" on:click={ gotoTickets }>No</button>
                    <p class="transfer-info">
                        Purchase tickets only
                    </p>
                </div>
                <div class="column transfer-column">
                    <button class="transfer-option" on:click={ () => cartView = CART_VIEW.BUS_TRANSFER }>Yes</button>
                    <p class="transfer-info">
                        Purchase tickets & two-way Watercity Bus Transfer
                    </p>
                </div>
            </div>
        </div>
    {:else if cartView == CART_VIEW.BUS_TRANSFER}
        <BusTransfer bind:cartView={ cartView } cancelCallback={ gotoHome } completeCallback={ gotoTickets }/>
    {:else if cartView == CART_VIEW.TICKETS}
        {#if $app.cartStep == 1}
            <CartStep1 cancelCallback={ gotoHome }/>
        {:else if $app.cartStep == 2}
            <CartStep2/>
        {:else}
            <CartStep3/>
        {/if}
    {/if}
</div>

<style>
    .transfer-column {
        width: 100%;
        max-width: 290px;
        padding: 10px 10px;
        margin: auto;
    }

    .transfer-option {
        display: block;
        width: 100%;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        color: #173091;
        background: #fff;
        border: none;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 10px 10px;
        cursor: pointer;
    }

    .transfer-option:hover {
        color: #fff;
        background: #173091;
    }

    .transfer-info {
        font-size: 14px;
        text-align: center;
        margin-top: 5px;
    }

    @media (min-width: 576px) {
        .transfer-column {
            width: 50%;
        }
    }
</style>