<script>
    import app from '../../stores/app';
    import {TICKET_CODES} from '../../lib/constants';
    import TicketsNumber from "../../controls/TicketsNumber.svelte";

    export let product = {};

    const resetProductPersons = () => {
        $app.products.map(item => {
            if (item.code === TICKET_CODES.FAMILY) {
                item.persons = 0;
            } else if (item.code === TICKET_CODES.ADULT) {
                item.minPersons = 1;
            }
            if (item.persons < item.minPersons) {
                item.persons = item.minPersons;
            }
        });
    }

    const onTicketsNumberChange = ({detail}) => {
        if (product.code === TICKET_CODES.FAMILY) {
            if (product.persons > 0) {
                // remove persons from any other products
                $app.products.map(item => {
                    if (item.code !== TICKET_CODES.FAMILY) {
                        item.minPersons = 0;
                        item.persons = 0;
                    }
                });
            } else {
                resetProductPersons();
            }
        } else {
            resetProductPersons();
        }
        $app.ticket_discount_id = product.classes[0].id;
    };

</script>
<div class="cart-product">
    <div class="product-name">
        { product.name }
    </div>
    <div class="row">

        <div class="column column-left">
            {#if product.code === TICKET_CODES.TODDLER}

                <div class="product-class-info product-toddler">
                    <div class="toddler-content">
                        <div class="free">ΔΩΡΕΑΝ</div>
                        <p class="toddler-warning">
                            * Όλα τα παιδιά κάτω των 12 ετών θα πρέπει να συνοδεύονται από ενήλικα που είναι
                            αποκλειστικά υπεύθυνος για τη φροντίδα, τη συμπεριφορά και την ασφάλειά τους εντός των
                            εγκαταστάσεων. Ως υπεύθυνος, είναι δική σας ευθύνη να αξιολογήσετε τις κολυμβητικές
                            ικανότητες και τους περιορισμούς που υπάρχουν, για τα παιδιά που είναι υπό τη φροντίδα σας.
                        </p>
                    </div>
                </div>

            {:else}

                {#each product.classes as cls}
                    <div class="product-class">
                        <div class="product-class-content">
                            <div class="product-class-prices">
                                {#if cls.discounted_price}
                                    <div class="price price-normal" class:selected={ product.persons > 0 }>
                                        { cls.discounted_price } €
                                    </div>
                                    <div class="price price-not">
                                        { cls.price.toFixed(2) } €
                                    </div>
                                {:else}
                                    <div class="price price-normal" class:selected={ product.persons > 0 }>
                                        { cls.price.toFixed(2)} €
                                    </div>
                                {/if}
                            </div>
                            <div class="product-class-info">
                                <div class="product-admission">
                                    { product.admissionTitle }
                                </div>
                                {#if cls.rules && cls.rules.length}
                                    <ul class="product-class-options">
                                        {#each cls.rules as rule}
                                            <li>{@html rule }</li>
                                        {/each}
                                    </ul>
                                {/if}
                                {#if cls.games && cls.games.length}
                                    <div class="game-categories">
                                        {#each cls.games as game}
                                            <div class="category">
                                                <img src="/app/assets/images/game-categories/{ game }.png">
                                            </div>
                                        {/each}
                                    </div>
                                {/if}
                            </div>
                        </div>
                    </div>
                {/each}

            {/if}
        </div>
        <div class="column column-right" style="{ product.code === TICKET_CODES.TODDLER ? 'margin-top:unset;' : ''}">
            <div class="product-image">
                <img src="/app/assets/images/ticket-groups/el/{ product.icon }.png">
            </div>
            <TicketsNumber label="{product.personsLabel }"
                           bind:value="{ product.persons }"
                           minValue="{ product.minPersons }"
                           maxValue="{ product.maxPersons }"
                           on:change={ onTicketsNumberChange }/>
        </div>
    </div>
</div>

<style>
    .column-left {
        width: calc(100% - 110px);
    }

    .column-right {
        width: 110px;
        padding-left: 20px;
        margin-top: -50px;
    }

    .product-name {
        display: block;
        font-size: 18px;
        font-weight: bold;
        line-height: 0.8;
        color: #163091;
        padding-bottom: 10px;
    }

    .cart-product .product-admission {
        font-size: 22px;
        line-height: 1;
        color: #54585b;
        padding-bottom: 5px;
    }

    .cart-product .product-class-prices {
        top: -55px;
    }

    .product-class-name {
        font-size: 22px;
        line-height: 1;
        color: #54585b;
        padding-bottom: 5px;
    }

    .cart-product .product-class-info {
        margin-left: 0 !important;
    }


    @media (max-width: 320px) {

    }

    @media (min-width: 576px) {
        .column-left {
            width: calc(100% - 164px);
        }

        .column-right {
            width: 144px;
            margin-top: -50px;
        }
    }
</style>