<script>
    import DateSelectorDateItem from "./DateSelectorDateItem.svelte";
    import {createEventDispatcher} from "svelte/internal";
    import {days, months} from "./lib";
    import {formatDate} from "../../lib/lib";
    import Icon from "../Icon.svelte";

    export let separator = "-";

    let today = formatDate(new Date(), 'y-m-d');
    const [tY, tM, tD] = today.split(separator);

    export let value = today;
    export let validator = x => x;
    export let dateClickCallback = () => false;

    export const refreshCalendar = () => {
        init(year, month, date);
    };

    let [year, month, date] = value.split(separator);


    const dispatch = createEventDispatcher();


    const selectDate = () => {
        dispatch("select");
    };

    let daysArray = [];
    let currentMonth = '';

    const isToday = (y, m, d) => {
        return (y == tY) && ((m + 1) == tM) && (d == tD);
    };
    const isPast = (ymd) => {
        return Number(today.replaceAll(separator, '')) > Number(ymd.replaceAll(separator, ''));
    };

    const innerDateClick = async dateObject => {
        if (dateObject.detail.classList.includes('disabled')) {
            return;
        }
        const res = dateClickCallback(dateObject);
        if (res !== false) {
            init(year, month, date);
        }
    };


    const init = (y, m, d) => {
        let dateNumber = 1;
        m--;
        if (m < 0) m = 11;

        currentMonth = months[m];

        let firstDay = (new Date(y, m)).getDay();
        let daysInMonth = 32 - (new Date(y, m, 32)).getDate();

        daysArray = [];

        for (let weekCounter = 0; weekCounter < 6; weekCounter++) {
            for (let dateCounter = 0; dateCounter < 7; dateCounter++) {
                const today = isToday(y, m, dateNumber);
                const ymd = `${y}${separator}${`${m + 1}`.padStart(2, "0")}${separator}${`${dateNumber}`.padStart(2, "0")}`;
                const past = isPast(ymd);


                let thisDate = {past, ymd, dateNumber, classList: [],};
                if (today) {
                    thisDate.classList = ['is-today'];
                }

                if (weekCounter == 0 && dateCounter < firstDay) {
                    thisDate.classList.push('is-past');
                    thisDate.dateNumber = '';
                } else if (dateNumber > daysInMonth) {
                    break;
                } else {
                    dateNumber++;
                }
                thisDate = validator(thisDate);

                daysArray.push(thisDate);
            }
        }
    };

    const selectPreviousMonth = () => {
        month--;
        if (month == -1) {
            month = 11;
            year--;
        }
    };

    const selectNextMonth = () => {
        month++;
        if (month == 13) {
            month = 1;
            year++;
        }
    };

    $: init(year, month, date);
</script>


<div class="sre-cal">
    <div class="texts-header-row">
        <div class="nav-arrow" on:click={ selectPreviousMonth }>
            <Icon icon="triangle-left" color="173091"/>
        </div>
        <div class="month-year">{currentMonth} {year}</div>
        <div class="nav-arrow" on:click={ selectNextMonth }>
            <Icon icon="triangle-right" color="173091"/>
        </div>
    </div>
    <div class="days-header">
        {#each days as day, index}
            <div class="calendar-cell">{day}</div>
        {/each}
    </div>
    <div class="dates">
        {#each daysArray as date}
            <DateSelectorDateItem {date} on:date-click={innerDateClick}/>
        {/each}
    </div>
</div>


<style>
    .sre-cal {
        padding: 2px;
        background: #fff;
        border-radius: 5px;
        -webkit-box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.28);
        box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.28);
    }

    .sre-cal :global(.calendar-cell) {
        border: 1px solid #ddd;
        padding: 10px 0;
        border-radius: 2px;
        text-align: center;
    }

    .texts-header-row {
        display: grid;
        grid-template-columns: min-content 1fr min-content;
        align-items: center;
    }

    .texts-header-row .month-year {
        color: #173091;
        font-size: 20px;
    }

    .days-header {
        padding-top: 2px;
        font-weight: bold;
    }

    .days-header > div {
        font-weight: normal;
        color: #54575a;
        background: transparent;
        /*border-radius: 2px;*/
        border: none !important;
    }

    .texts-header-row > * {
        text-align: center;
    }

    .days-header, .dates {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    .nav-arrow {
        padding: 5px;
        cursor: pointer;
    }
</style>