<script>
    import {createEventDispatcher} from 'svelte/internal';
    import Icon from "../components/Icon.svelte";

    export let label = "";
    export let block = false;
    export let type = 'next';
    export let preventDefault = false;

    const dispatch = createEventDispatcher();

    const onClick = (event) => {
        if (preventDefault) {
            event.preventDefault();
            event.stopPropagation();
        }
        dispatch("click");
    }
</script>


<button class="button { type }" class:block="{ block }" on:click={ onClick }>
    {#if type === 'back'}
        <span class="icon">
            <Icon icon="triangle-left" size="35" color="ffffff"/>
        </span>
    {/if}
    <span class="label">{ label }</span>
    {#if type === 'next'}
        <span class="icon">
            <Icon icon="triangle-right" size="35" color="ffffff"/>
        </span>
    {/if}
</button>


<style>
    button {
        position: relative;
        cursor: pointer;
        color: #fff;
        background: #00a1cb;
        border-style: none;
        border-radius: 40px;
        padding: 3px 15px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        overflow-x: hidden;
    }

    button:hover {
        background: #173091;
    }

    button.next {
        text-align: left;
    }

    button.back {
        text-align: right;
    }

    .button.block {
        width: 100%;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
    }

    button.back .icon {
        left: 5px;
        right: unset;
    }

    .label {
        display: inline-block;
        padding: 10px 0;
    }

    @media (max-width: 375px) {
        button {
            font-size: 14px;
        }
    }

    @media (min-width: 576px) {
        button {
            font-size: 18px;
            border-radius: 10px;
            padding: 10px 20px;
        }

        .icon {
            right: 10px;
        }

        button.back .icon {
            left: 10px;
        }
    }
</style>