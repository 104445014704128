<script>
    import appAlert, {ALERTS} from "../stores/appAlert";

    const onOkClick = () => {
        if ($appAlert.yesCallback) {
            $appAlert.yesCallback();
        }
        appAlert.hide();
    }
</script>
{#if $appAlert.type != ALERTS.NOTHING}
    <div class="alert-overlay">
        <div class="alert-panel">
            <div class="alert-panel-body">
                {@html $appAlert.prompt }
            </div>
            <div class="alert-panel-footer">
                {#if $appAlert.type == ALERTS.ERROR}
                    <button class="error-button" on:click={ onOkClick }>OK</button>
                {/if}
            </div>
        </div>
    </div>
{/if}
<style>
    .alert-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*background: #ccc;*/
        z-index: 99999;
    }

    .alert-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        background: #fff;
        border-radius: 10px;
        -webkit-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 70%);
        box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 70%);
    }

    .alert-panel .alert-panel-body {
        line-height: 1.5;
        text-align: center;
        padding: 20px 20px 0 20px;
    }

    .alert-panel .alert-panel-footer {
        text-align: center;
        padding: 20px 20px 20px 20px;
    }

    .alert-panel button {
        color: #fff;
        background: #00a1cb;
        border-style: none;
        border-radius: 40px;
        padding: 3px 15px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        overflow-x: hidden;
        cursor: pointer;
    }


    @media (min-width: 576px) {
        .alert-panel {
            width: max-content;
            min-width: 400px;
        }

        .alert-panel button {
            font-size: 18px;
            border-radius: 10px;
            padding: 10px 20px;
        }
    }
</style>