<script>
    import {formatDate, dateAfter} from '../../lib/lib';
    import {createEventDispatcher} from "svelte/internal";
    import DateSelector from "./DateSelector.svelte";
    import Icon from "../Icon.svelte";

    export let label = "DATE";
    export let value = '';
    export let validDates = null;
    export let disabledDates = null;
    export let startDate = null;
    export let endDate = null;

    let selector;

    let showDateSelector = false;
    let currentYearMonth = null;
    let validDatesLength = 0;

    const dispatch = createEventDispatcher();

    const bindClick = () => window.addEventListener('click', onWindowClick);

    const onWindowClick = (event) => {
        let container = document.getElementById("date-dropdown");
        if (container && container !== event.target && !container.contains(event.target)) {
            showDateSelector = false;
            window.removeEventListener('click', onWindowClick);
        }
    }

    const openDateSelector = () => {
        showDateSelector = true;
        setTimeout(bindClick);
    }

    const dateClickCallback = dateObject => {
        value = dateObject.detail.ymd;
        showDateSelector = false;
        window.removeEventListener('click', onWindowClick);
        dispatch('change', dateObject.detail.ymd);
    }

    const dateValidator = (dateObject) => {
        let yearMonth = formatDate(dateObject.ymd, 'y-m');
        if (currentYearMonth !== yearMonth) {
            currentYearMonth = yearMonth;
            dispatch('year_month_change', currentYearMonth);
        }

        if (startDate && dateObject.ymd < startDate) {
            dateObject.classList.push('disabled');
        } else if (endDate && dateObject.ymd > endDate) {
            dateObject.classList.push('disabled');
        } else {
            if (dateObject.ymd && dateObject.ymd === value) {
                dateObject.classList.push('selected');
            }
            if (validDates && !validDates.includes(dateObject.ymd)) {
                dateObject.classList.push('disabled');
            }
            if (disabledDates && disabledDates.includes(dateObject.ymd)) {
                dateObject.classList.push('disabled');
            }
        }
        return dateObject;
    }

    const initialize = () => {
        if (!value) {
            if (startDate) {
                value = startDate;
            } else {
                value = dateAfter('today', 1);
            }
            if (validDates && validDates.length) {
                if (value < validDates[0]) {
                    value = validDates[0];
                }
            }

            if (disabledDates) {
                disabledDates.map(dateObj => {
                    if (value === dateObj) {
                        value = dateAfter(dateObj, 1);
                    }
                });
            }
        }

        currentYearMonth = formatDate(value, 'y-m');
    }

    initialize();

    $:{
        if (validDates != null && validDates.length !== validDatesLength) {
            validDatesLength = validDates.length;
            if (selector) {
                selector.refreshCalendar();
            }
        }
    }
</script>

<div class="form-group">
    <label>{ label }</label>
    <div class="date-picker">
        <div class="date-picker-input" on:click={ openDateSelector }>
            <input type="text" value="{ formatDate(value, 'd-m-y') }"
                   placeholder="select excursion date" readonly
            >
            <div class="icon">
                <Icon icon="calendar"/>
            </div>
        </div>
        <div class="date-dropdown" id="date-dropdown" class:hidden={ ! showDateSelector }>
            <DateSelector bind:this={ selector } { dateClickCallback } validator={ dateValidator } value="{ value }"/>
        </div>
    </div>
</div>

<style>
    .date-picker {
        position: relative;
    }

    .date-picker-input {
        position: relative;
    }

    .date-picker-input input {
        cursor: pointer;
    }

    .date-picker-input .icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }

    .date-dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        min-width: 280px;
        z-index: 100;
    }

    .date-dropdown.hidden {
        display: none;
    }
</style>