<script>
    import {createEventDispatcher} from 'svelte/internal';

    export let label = '';
    export let checked = true;
    export let classId = 0;
    export let isBig = false;

    const dispatch = createEventDispatcher();

    const onClick = () => {
        dispatch("click", classId);
    };
</script>

<div class="checkbox" on:click="{ onClick }">
    <div class="square">
        <div class="icon">
            {#if checked}
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 45 45" fill="#fcb042" xml:space="preserve"
                     stroke="#fcb042" stroke-width="4">
                <path d="M1.36,17.427c0,0,7.311-0.122,10.844,8.163c0,0,15.474-22.175,31.435-18.885c0,0-17.789,7.067-32.045,31.922L1.36,17.427z"
                />
                </svg>
            {/if}
        </div>
    </div>
    <div class="caption" class:is-big={ isBig }>{ label }</div>
</div>

<style>
    .checkbox {
        color: #163091;
        cursor: pointer;
        margin-bottom: 5px;
    }

    .checkbox:after {
        content: "";
        clear: both;
        display: table;
    }

    .square {
        position: relative;
        float: left;
        width: 16px;
        height: 16px;
        border: 3px solid #54585b;
        /*border-radius: 8px;*/
        border-radius: 3px;
        margin-right: 4px;
        margin-top: 4px;
    }

    .icon {
        position: absolute;
        bottom: -11px;
        right: -5px;
        width: 15px;
        height: 23px;
    }

    .caption {
        float: left;
        font-size: 20px;
        font-weight: 500;
    }

    .caption.is-big {
        font-size: 22px;
        font-weight: bolder;
    }

    @media (min-width: 576px) {
        .checkbox {
            margin-bottom: 10px;
        }

        .square {
            width: 22px;
            height: 22px;
            border: 2px solid #54585b;
            border-radius: 3px;
            margin-right: 10px;
            margin-top: 2px;
        }

        .icon {
            bottom: -9px;
            right: -5px;
            width: 23px;
            height: 26px;
        }

        .caption {
            font-size: 25px;
        }
    }
</style>