<script>
    import app from '../../stores/app';
    import {TICKET_CODES, TRANSFER_TYPE} from '../../lib/constants';
    import TicketCheckbox from "../../controls/TicketCheckbox.svelte";
    import TicketsNumber from "../../controls/TicketsNumber.svelte";

    export let product = {};

    const onClassClick = ({detail: classId}) => {
        $app.ticket_discount_id = classId;
    };

</script>
<div class="cart-product">
    <div class="product-name">
        { product.name }
    </div>
    <div class="row align-items-center">

        <div class="column column-left">
            {#if product.code === TICKET_CODES.TODDLER}

                <div class="product-class-info product-toddler">
                    <div class="toddler-content">
                        <div class="free">FREE</div>
                        <p class="toddler-warning">
                            * All children under the age of 12 must be accompanied by an adult who is solely responsible
                            for their care, behavior and safety within the facility. As a supervisor, it is your
                            responsibility to assess the swimming skills and limitations of the children under your
                            care.
                        </p>
                    </div>
                </div>

            {:else}

                {#each product.classes as cls}
                    {#if cls.is_active}
                        <div class="product-class">
                            <div class="product-class-content">
                                <TicketCheckbox label="{ cls.title }" classId="{ cls.id }"
                                                isBig={ cls.code === 'FLEXY' }
                                                checked={ $app.ticket_discount_id == cls.id } on:click="{ onClassClick }"/>
                                <div class="product-class-prices">
                                    {#if cls.discounted_price}
                                        <div class="price price-normal"
                                             class:selected={ $app.ticket_discount_id == cls.id }>
                                            { cls.discounted_price } €
                                        </div>
                                        <div class="price price-not">
                                            { cls.price.toFixed(2) } €
                                        </div>
                                    {:else}
                                        <div class="price price-normal"
                                             class:selected={ $app.ticket_discount_id == cls.id }>
                                            { cls.price.toFixed(2)} €
                                        </div>
                                    {/if}
                                </div>
                                <div class="product-class-info">
                                    <div class="product-admission">
                                        { product.admissionTitle }
                                    </div>
                                    {#if cls.rules && cls.rules.length}
                                        <ul class="product-class-options">
                                            {#each cls.rules as rule}
                                                <li>{@html rule }</li>
                                            {/each}
                                        </ul>
                                    {/if}
                                    {#if cls.games && cls.games.length}
                                        <div class="game-categories">
                                            {#each cls.games as game}
                                                <div class="category">
                                                    <img src="/app/assets/images/game-categories/{ game }.png">
                                                </div>
                                            {/each}
                                        </div>
                                    {/if}
                                </div>
                            </div>
                        </div>
                    {/if}
                {/each}

            {/if}
        </div>
        <div class="column column-right"
             class:only-one-class="{ $app.transfer_type_id != TRANSFER_TYPE.NO_TRANSFER || product.code === TICKET_CODES.TODDLER}">
            <div class="product-image">
                <img src="/app/assets/images/ticket-groups/en/{ product.icon }.png">
            </div>
            <TicketsNumber label="{product.personsLabel }" bind:value="{ product.persons }"
                           minValue="{ product.minPersons }"
                           maxValue="{ product.maxPersons }"/>
        </div>
    </div>
</div>

<style>
    .column-left {
        width: calc(100% - 110px);
    }

    .column-right {
        width: 110px;
        padding-left: 20px;
        margin-top: -40%;
    }

    .column-right.only-one-class {
        margin-top: unset !important;
    }


    @media (min-width: 576px) {
        .column-left {
            width: calc(100% - 164px);
        }

        .column-right {
            width: 144px;
            margin-top: -20%;
        }
    }
</style>