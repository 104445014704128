<script>
    import app from "../stores/app";
    import {TICKET_CODES, TRANSFER_TYPE} from '../lib/constants';
    import Button from "../controls/Button.svelte";
    import {formatDate, setGtagEvent} from "../lib/lib";

    let selectedTicketClass = null;

    const getItemCategory = code => {
        if (code === 'ADULT') {
            return "Adults";
        }
        if (code === 'CHILD') {
            return "Children";
        }
        if (code === 'INFANT') {
            return "Toddlers";
        }
        if (code === 'FAMILY') {
            return "Family";
        }
    }

    const getTicketName = product => {
        let arr = ["", "Flexy", "Discounted", "Greek", ""];

        let name = "";
        if (product.code === TICKET_CODES.ADULT) {
            name = "General Admission";
        }
        if (product.code === TICKET_CODES.CHILD) {
            name = "Kids Admission";
        }
        if (product.code === TICKET_CODES.TODDLER) {
            name = "Toddlers";
        }
        if (product.code === TICKET_CODES.FAMILY) {
            name = "Greek Family Offer";
        }

        if ($app.transfer) {
            name+= " Transfer";
        }

        name+= " " + arr[$app.ticket_discount_id];
        return name.trim();
    }

    const initialize = () => {
        selectedTicketClass = $app.settings.discounts.find(x => x.id == $app.ticket_discount_id);

        calculateTotals();

        // Google analytics
        $app.gTagItems = [];
        $app.products.map(item => {
            if (item.persons > 0) {
                let gTagItem = {
                    item_id: item.id,
                    item_name: getTicketName(item),
                    affiliation: "watercity.gr",
                    // currency: "EUR",
                    index: 0,
                    item_category: getItemCategory(item.code), // Adults, Children ...
                    price: `${item.totalPrice.toFixed(2)}`,
                    quantity: item.persons,
                }
                $app.gTagItems.push(gTagItem);
            }
        });

        setGtagEvent("add_to_cart", '', $app.totalPrice, '', '', $app.gTagItems);
    }

    const calculateTotals = () => {
        $app.totalPersons = 0;
        $app.totalPrice = 0;
        $app.totalTicketsPrice = 0;
        $app.totalTransferPrice = 0;

        $app.products.map(item => {
            item.ticketsPrice = 0;
            item.transferPrice = 0;
            item.totalPrice = 0;

            let cls = item.classes.find(x => x.id == $app.ticket_discount_id);
            if (cls) {
                item.ticketsPrice = (cls.discounted_price ? cls.discounted_price : cls.price) * item.persons;
                // add transfer price in product price
                if ($app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER) {
                    item.transferPrice = parseFloat($app.transfer.prices[item.code]) * item.persons;
                }
                item.totalPrice = item.ticketsPrice + item.transferPrice;
            }

            $app.totalPersons += item.persons;
            $app.totalTicketsPrice += item.ticketsPrice;
            $app.totalTransferPrice += item.transferPrice;
            $app.totalPrice += item.totalPrice;
        });
    };

    initialize();
</script>

<div class="container mt-lg">
    {#if $app.transfer}
        <div class="row">
            <div class="column column-1"></div>
            <div class="column">
                <div class="title">TWO WAY BUS TRANSFER</div>
                {#if $app.transfer.hotel}
                    <p class="transfer-row">
                        <span class="transfer-label">Your Hotel:</span>
                        <span class="transfer-data">{ $app.transfer.hotel.name }</span>
                    </p>
                    <p class="transfer-row">
                        <span class="transfer-label">Location:</span>
                        <span class="transfer-data">{ $app.transfer.hotel.area }</span>
                    </p>
                {/if}
                <p class="transfer-row">
                    <span class="transfer-label">Your Pickup Point:</span>
                    <span class="transfer-data">{ $app.transfer.pickup_point.name }</span>
                </p>
                <p class="transfer-row">
                    <span class="transfer-label">Pickup Date & Time:</span>
                    <span class="transfer-data">{ formatDate($app.transfer.date_at, 'd-m-y') } { $app.transfer.pickup_time }</span>
                </p>
                <p class="transfer-row">
                    Your instructions will be sent via e-mail
                </p>
            </div>
        </div>
        <hr>
    {/if}
    {#each $app.products as product}
        <div class="row">
            <div class="column column-1 tickets-num">
                <div class="tickets-number">
                    <div class="number">{ product.persons }</div>
                </div>
                <div class="tickets-number-x">x</div>
            </div>
            <div class="column column-2">
                <div class="title">{ product.name }</div>
                {#if product.code != TICKET_CODES.TODDLER}
                    <div class="admission">
                        { product.admissionTitle }
                        {#if $app.transfer_type_id == TRANSFER_TYPE.BUS_TRANSFER}
                            <!-- BUS TRANSFER PRICES -->
<!--                            <br>Bus Transfer-->
                            <br>with two-way Bus Transfer
                        {/if}
                    </div>
                    {#if selectedTicketClass && $app.language === 'en'}
                        <div class="ticket-class">
                            { selectedTicketClass.name }
                        </div>
                    {/if}
                {/if}
            </div>
            <div class="column column-3">
                <div class="admission-price">
                    {#if product.code != TICKET_CODES.TODDLER}
                        {#if product.totalPrice > 0}
                            { product.totalPrice.toFixed(2) } €
                            <!-- BUS TRANSFER PRICES -->
                            <!--{#if product.transferPrice > 0}-->
                            <!--    <div class="sub-total">{ product.ticketsPrice.toFixed(2) } €</div>-->
                            <!--    <div class="sub-total">{ product.transferPrice.toFixed(2) } €</div>-->
                            <!--{/if}-->
                        {/if}
                    {:else}
                        {#if product.persons > 0}
                            { $app.lang.free }
                        {/if}
                    {/if}
                </div>
            </div>
        </div>
        <hr>
    {/each}
    <!-- BUS TRANSFER PRICES -->
    <!--{#if $app.totalTransferPrice > 0}-->
    <!--    <div class="row align-items-center mb-sm">-->
    <!--        <div class="col-6 subtotals-left color-primary">-->
    <!--            Admission-->
    <!--        </div>-->
    <!--        <div class="col-6 subtotals-right text-right color-primary">-->
    <!--            { $app.totalTicketsPrice.toFixed(2) } €-->
    <!--        </div>-->
    <!--    </div>-->
    <!--    <div class="row align-items-center mb-sm">-->
    <!--        <div class="col-6 subtotals-left color-primary">-->
    <!--            Transfer-->
    <!--        </div>-->
    <!--        <div class="col-6 subtotals-right text-right color-primary">-->
    <!--            { $app.totalTransferPrice.toFixed(2) } €-->
    <!--        </div>-->
    <!--    </div>-->
    <!--{/if}-->
    <div class="row align-items-bottom">
        <div class="col-6 totals-left color-primary">
            { $app.lang.total }
        </div>
        <div class="col-6 totals-right text-right color-primary">
            <strong>{ $app.totalPrice.toFixed(2) } €</strong>
        </div>
    </div>

    <div class="row buttons-row">
        <div class="col-6">
            <Button label="{ $app.lang.btnBack }" type="back" block on:click={ () => $app.cartStep = 1 }/>
        </div>
        <div class="col-6">
            <Button label="{ $app.lang.btnNext }" type="next" block on:click={ () => $app.cartStep = 3 }/>
        </div>
    </div>
    <div class="how-to-pay">
        <div class="cards">
            <img src="/app/assets/images/how-to-pay.png" alt="how to pay">
        </div>
    </div>
</div>

<style>
    .column-1 {
        width: 14%;
        padding-right: 2px;
    }

    .column-2 {
        width: 61%;
    }

    .column-3 {
        width: 25%;
    }

    .tickets-number {
        position: relative;
        float: left;
        width: 25px;
        height: 25px;
        color: #54575a;
        background: #fff;
        border: 2px solid #54575a;
        border-radius: 3px;
    }

    .tickets-number .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }

    .tickets-number-x {
        float: right;
        color: #173091;
        font-size: 20px;
        font-weight: bold;
        margin-left: 5px;
    }

    .title {
        font-size: 20px;
        font-weight: 500;
        color: #fcb040;
    }

    .admission {
        font-size: 16px;
        font-weight: bold;
        color: #173091;
    }

    .admission-price {
        width: 100%;
        font-size: 23px;
        font-weight: bold;
        text-align: right;
        color: #173091;
    }

    .admission-price .sub-total {
        font-weight: normal;
        font-size: 16px;
        color: #54575a;
    }

    .ticket-class {
        font-size: 14px;
    }

    .subtotals-left {
        font-size: 18px;
        font-weight: bold;
    }

    .subtotals-right {
        font-size: 23px;
        font-weight: bold;
    }

    .totals-left {
        font-size: 31px;
        font-weight: bold;
    }

    .totals-right {
        font-size: 45px;
        font-weight: bold;
    }

    .transfer-row {
        margin: 5px 0;
    }

    .transfer-label {
        /*display: block;*/
        display: inline-block;
        font-size: 17px;
        font-weight: bold;
        color: #173091;
    }

    .transfer-data {
        /*display: block;*/
        display: inline-block;
        font-weight: bold;
        padding-left: 10px;
    }

    @media (max-width: 375px) {
        .column-1 {
            width: 18%;
        }

        .column-2 {
            width: 52%;
        }

        .column-3 {
            width: 30%;
        }

        .tickets-number {
            width: 25px;
            height: 25px;
        }

        .admission {
            font-size: 15px;
        }
    }

    @media (min-width: 576px) {
        .column-1 {
            width: 8%;
        }

        .column-2 {
            width: 77%;
        }

        .column-3 {
            width: 15%;
        }
    }
</style>